import React, { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    // Redirect user to 'https://www.personapassions.ai'
    window.location.href = 'https://www.personapassions.ai';
  }, []); // The empty array ensures the redirect only happens on component mount.

  return (
    <div>
      Redirecting to <a href="https://www.personapassions.ai/" target="_blank" rel="noopener noreferrer">Persona Passions</a>...
    </div>
  );
};

export default Home;
