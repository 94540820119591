/* global Telegram */
import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Home  from "./Home";
import "./App.css";

console.log(`REACT_APP_STRIPE_PUBLISHABLE_KEY:   ${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const price_id = urlParams.get('price_id');
    const telegram_user_id = urlParams.get('telegram_user_id');
    const telegram_bot_id = urlParams.get('telegram_bot_id');

    console.log(`price_id ${price_id}`)
    // Create a Checkout Session as soon as the page loads
    console.log(`${process.env.REACT_APP_API_URL}/create-checkout-session?price_id=${price_id}&telegram_user_id=${telegram_user_id}&telegram_bot_id=${telegram_bot_id}`)
    
    fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session?price_id=${price_id}&telegram_user_id=${telegram_user_id}&telegram_bot_id=${telegram_bot_id}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        console.log(data.clientSecret)
       setClientSecret(data.clientSecret)
      });
  }, []);

  return (
    <div className="App-header"  id="checkout">
      <h5>Persona Passions Checkout</h5>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}



const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    console.log(`queryString ${queryString}`)
    console.log(`urlParams ${urlParams}`)
    console.log(`sessionId ${sessionId}`);
    console.log(`${process.env.REACT_APP_API_URL}/session-status?session_id=${sessionId}`)
    fetch(`${process.env.REACT_APP_API_URL}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(`data ${JSON.stringify(data)}`)
        console.log(`data.status ${data.status}`)
        console.log(`data.customer_email ${data.customer_email}`)
        setStatus(data.status);
        setCustomerEmail(data.customer_email);

        Telegram.WebApp.ready();
        Telegram.WebApp.MainButton.setText('Return to Telegram Bot').show().onClick(function () {
            const return_data = JSON.stringify({customer_email: data.customer_email, order_status: data.status});
            console.log(return_data);
            Telegram.WebApp.sendData(return_data);
            Telegram.WebApp.close();
        });
      });
    
    //Telegram.WebApp.expand()

  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section className="App-header" id="success">
        <p>
          A confirmation email will be sent to {customerEmail}.
          If you have any questions, please email <a href="mailto:orders@personapassions.ai">orders@personapassions.ai</a>.
          Enjoy your Persona Passions Bot! 
        </p>
      </section>
    )
  }

  return null;
}


const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/return" element={<Return />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App;
